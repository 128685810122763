<template>
  <div class="tools">
    <v-container v-bind="options">
      <vue-table
          v-if="data.items && data.items.length"
          small-padding
          head-background
          :hoverable-rows="false"
      >
        <template #colgroup>
          <col style="width: 50px">
          <col style="width: 30%">
        </template>
        <template #head>
          <vue-line type="head">
            <vue-item>#</vue-item>
            <vue-item>{{ $t(contentPathSegmentsTable)['Name'] }}</vue-item>
            <vue-item>Проект</vue-item>
            <vue-item>{{ $t(contentPathSegmentsTable)['Type'] }}</vue-item>
            <vue-item align="center">{{ $t(contentPathSegmentsTable)['Status'] }}</vue-item>
            <vue-item>{{ $t(contentPathSegmentsTable)['User'] }}</vue-item>
            <vue-item>{{ $t(contentPathSegmentsTable)['Date'] }}</vue-item>
            <vue-item align="center">{{ $t(contentPathSegmentsTable)['Download'] }}</vue-item>
          </vue-line>
        </template>

        <template #body>
          <v-table-row
              v-for="(item, i) in data.items"
              :key="i"
              :item="item"
          />
        </template>
      </vue-table>
      <v-no-data v-else/>
    </v-container>
  </div>
</template>

<script>
import container from '@/services/components/container/'
import tableRow from './tableRow'
import noData from '@/services/errors/noData'

const contentPathSegmentsTable = 'services.segments.table'

export default {
  components: {
    'v-container': container,
    'v-table-row': tableRow,
    'v-no-data': noData
  },

  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      }
    },

    data: {
      type: [Array, Object],
      default: function () {
        return {}
      }
    }
  },

  data () {
    return {
      contentPathSegmentsTable
    }
  }
}
</script>
