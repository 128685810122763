import {mapState, mapActions} from 'vuex'
import {findOptions, generateTitle} from '@/function'
import ROUTE_NAME from '@/router/routeName'
import MODAL_TITLE from '@/modals/modalTitle'
import {NOTIFY_STATUS} from '@/const'

import error from '@/services/errors/block'
import table from './components/table/'
import subsegment from './components/subsegment/'
import find from './components/find/'

const contentPathSegmentsCatalog = 'services.segments.catalog'

export default {
  metaInfo () {
    return {
      title: generateTitle(`${this.title.primary} / ${this.title.secondary}`, this.activeProjectData)
    }
  },

  components: {
    'v-error': error,
    'v-table': table,
    'v-subsegment': subsegment,
    'v-find': find
  },

  data () {
    return {
      contentPathSegmentsCatalog,

      title: {
        primary: 'Segments',
        secondary: 'Content'
      },

      /* ID активного сегмента */
      activeCatalogId: '1',

      preloader: false,

      ROUTE_NAME,
      MODAL_TITLE
    }
  },

  computed: {
    ...mapState({
      /* Данные по активному проекту */
      activeProjectData: state => state.active_project_data,

      /* Данные по каталогу */
      catalog: state => state.segments.catalog.data,

      /* Данные по последним заданиям */
      taskList: state => state.segments.catalog.taskList
    }),

    /* Данные по активному сегменту */
    activeSegmentData () {
      if (this.activeCatalogId && this.catalog.has_data) {
        let subsegments = findOptions(this.activeCatalogId, this.catalog.tree)
        if (subsegments) {
          subsegments['links'] = []
          subsegments['categories'] = []
          for (let i in subsegments.items) {
            let item = subsegments.items[i]

            if (!!item.items && !!item.items.length) {
              subsegments.categories.push(item)
            } else {
              subsegments.links.push(item)
            }
          }
        }

        return subsegments
      }
    },

    /* ID активного проекта в URL */
    activeProjectId () {
      let id = +this.$route.params.activeProject
      return id || undefined
    },

    /* ID активного каталога */
    urlCatalogId () {
      let id = this.$route.query.catalog_id
      return id || undefined
    }
  },

  watch: {
    $route (to, from) {
      if (to.params.activeProject !== from.params.activeProject) {
        this.initData()
      }
    }
  },

  created () {
    this.initData()
  },

  methods: {
    ...mapActions({
      getTaskList: 'segments/catalog/getTaskList'
    }),

    async initData () {
      this.preloader = true

      /* Установка активного таба */
      if (this.catalog.has_data && this.urlCatalogId && findOptions(this.urlCatalogId, this.catalog.tree)) {
        this.$set(this, 'activeCatalogId', this.urlCatalogId)
      } else if (this.catalog.has_data && findOptions('1', this.catalog.tree)) {
        this.$set(this, 'activeCatalogId', '1')
      } else if (this.catalog.has_data && this.catalog.tree.length && this.catalog.tree[0]) {
        this.$set(this, 'activeCatalogId', this.catalog.tree[0].id)
      }

      try {
        await this.getTaskList({
          query: {
            project_id: this.activeProjectId,
            skip_regionality: 1,
            limit: 10
          }
        })
      } catch ({message}) {
        this.$notify({type: NOTIFY_STATUS.ERROR, title: message})
      }

      this.preloader = false
    },

    goToCatalogSection (catalogId) {
      let queryCatalogId = this.$route.query.catalog_id

      if (queryCatalogId !== catalogId) {
        this.$router.push({
          query: {
            catalog_id: catalogId
          }
        })
      }
    }
  }
}
