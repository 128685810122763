var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.visibleSegment)?_c('li',{staticClass:"find-segment"},[_c('router-link',{staticClass:"find-segment__name",attrs:{"to":{
      name: _vm.ROUTE_NAME.SEGMENTS_CONTENT_ITEM,
      params: {
        activeProject: _vm.projectId,
        catalogId: _vm.data.catalog.id,
        segmentId: _vm.data.id
      }
    }}},[_vm._v(" "+_vm._s(_vm.data.name)+" ")]),_c('ul',{staticClass:"find-breadcrumbs"},_vm._l((_vm.breadcrumbs),function(crumb,i){return _c('li',{key:i,staticClass:"find-breadcrumbs__crumb"},[(crumb.path)?_c('router-link',{staticClass:"find-breadcrumbs__link",attrs:{"to":crumb.path}},[_vm._v(" "+_vm._s(crumb.title)+" ")]):_c('span',[_vm._v(_vm._s(crumb.title))])],1)}),0)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }