<template>
  <li
      v-if="visibleSegment"
      class="find-segment"
  >
    <router-link
      class="find-segment__name"
      :to="{
        name: ROUTE_NAME.SEGMENTS_CONTENT_ITEM,
        params: {
          activeProject: projectId,
          catalogId: data.catalog.id,
          segmentId: data.id
        }
      }"
    >
      {{ data.name }}
    </router-link>
    <ul class="find-breadcrumbs">
      <li
          v-for="(crumb, i) in breadcrumbs"
          :key="i"
          class="find-breadcrumbs__crumb"
      >
        <router-link
            v-if="crumb.path"
            class="find-breadcrumbs__link"
            :to="crumb.path"
        >
          {{ crumb.title }}
        </router-link>
        <span v-else>{{ crumb.title }}</span>
      </li>
    </ul>
  </li>
</template>

<script>
import {mapGetters} from 'vuex'

import ROUTE_NAME from '@/router/routeName'

const contentPathSegmentsCatalog = 'services.segments.catalog'

export default {
  props: {
    projectId: {
      type: [Number, String]
    },

    data: {
      type: Object
    }
  },

  data () {
    return {
      ROUTE_NAME
    }
  },

  computed: {
    ...mapGetters({
      breadcrumbsCatalog: 'segments/catalog/breadcrumbsCatalog'
    }),

    visibleSegment () {
      try {
        return !!this.data && !!this.projectId && !!this.data.id && !!this.data.type && !!this.data.catalog.id
      } catch (_) {
        return false
      }
    },

    /* Хлебные крошки раздела каталога */
    breadcrumbsCatalogData () {
      return this.breadcrumbsCatalog(this.data.catalog.id)
    },

    breadcrumbs () {
      let breadcrumbsList = this.breadcrumbsCatalogData
        ? JSON.parse(JSON.stringify(this.breadcrumbsCatalogData.breadcrumbs))
        : [{title: this.itemCatalogData.name}]

      let breadcrumbs = [
        {title: this.$t(contentPathSegmentsCatalog)['Segments']},
        {title: this.$t(contentPathSegmentsCatalog)['Segments Catalog']}
      ].concat(breadcrumbsList)

      breadcrumbs.map(item => {
        switch (true) {
          case item.tab:
            item.path = {
              name: ROUTE_NAME.SEGMENTS_CONTENT_INDEX,
              params: {activeProject: this.projectId},
              query: {catalog_id: item.id}
            }
            break

          case item.selected:
            item.path = {
              name: ROUTE_NAME.SEGMENTS_CONTENT_LIST,
              params: {
                activeProject: this.projectId,
                catalogId: item.id
              }
            }
            break
        }
        return item
      })

      return breadcrumbs
    }
  }
}
</script>

<style lang="scss" scoped>
  .find {
    position: relative;
  }

  .find-segments {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 8px 16px;
    z-index: 2;
    max-height: 300px;
    overflow: auto;
    background: #FFFFFF;
    box-shadow: 0 0 8px rgba(69, 81, 101, 0.15), 0 8px 8px rgba(69, 81, 101, .15);
    border-radius: 6px;
  }

  .find-segment {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 8px 0;

    &__name {
      padding: 4px 0;
      font-size: 14px;
      line-height: 16px;
      color: #455165;
    }
  }

  .find-breadcrumbs {
    padding: 4px 0;
    font-size: 12px;
    line-height: 16px;
    color: #BDBDBD;
    display: flex;
    align-items: center;

    &__crumb {
      &:not(:first-child):before {
        content: ' > ';
      }
    }

    &__link {
      &:hover {
        color: #3E9BEF;
      }
    }
  }
</style>
