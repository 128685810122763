var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"dashboard-container _column catalog"},[_c('v-page-title',{attrs:{"primary":_vm.title.primary,"secondary":_vm.title.secondary}}),_c('v-button-group',{attrs:{"type":"tabs-big","narrow":"","new-design":"","options":[
        {
          to: _vm.$route.fullPath,
          name: _vm.$t(_vm.contentPathSegmentsCatalog)['Segments'],
          active: true,
        },
        {
          name: _vm.$t(_vm.contentPathSegmentsCatalog)['Completed tasks'],
          disabled: true,
          to: '/'
        }
      ],"dataforce":""}}),_c('div',{staticClass:"dashboard-block _mb-32"},[(_vm.catalog.has_data)?_c('div',{staticClass:"catalog__wrapper"},[_c('div',{staticClass:"catalog__content"},[_c('div',{staticClass:"dashboard-block _mb-32"},[_c('h5',{staticClass:"catalog__title"},[_vm._v(" "+_vm._s(_vm.$t(_vm.contentPathSegmentsCatalog)['Segments Catalog'])+" ")]),_c('v-find',{staticClass:"catalog__find"})],1),_c('div',{staticClass:"dashboard-block _mb-32"},[_c('v-button-group',{staticClass:"catalog__tabs",attrs:{"type":"buttons","new-design":"","options":_vm.catalog.tree,"dataforce":""},on:{"input":_vm.goToCatalogSection},model:{value:(_vm.activeCatalogId),callback:function ($$v) {_vm.activeCatalogId=$$v},expression:"activeCatalogId"}})],1),_c('div',{staticClass:"dashboard-block _mb-32 _row _border-bottom"},[(_vm.activeSegmentData && _vm.activeSegmentData.description)?_c('div',{staticClass:"catalog__about"},[_vm._v(" "+_vm._s(_vm.activeSegmentData.description)+" ")]):_vm._e(),_c('v-button',{staticClass:"catalog__new-button",attrs:{"name":_vm.$t(_vm.contentPathSegmentsCatalog)['New segment'],"icon":"add","offset-icon":"4px","padding-left":"8px","dataforce":""},on:{"click":function($event){return _vm.$modal.open({
                  name: _vm.MODAL_TITLE.SEGMENT_CREATE,
                  right: true,
                  props: {projectId: _vm.activeProjectId}
              })}}})],1),(!!_vm.activeSegmentData && !!_vm.activeSegmentData.links && !!_vm.activeSegmentData.links.length)?_c('v-subsegment',{attrs:{"links":_vm.activeSegmentData.links}}):_vm._e(),(!!_vm.activeSegmentData && !!_vm.activeSegmentData.categories && !!_vm.activeSegmentData.categories.length)?_vm._l((_vm.activeSegmentData.categories),function(category){return _c('v-subsegment',{key:category.id,attrs:{"category":category}})}):_vm._e()],2)]):_c('v-error',{attrs:{"title":_vm.$t('modules.pages')['Error'],"subtitle":_vm.$t('modules.pages')['No access to projects']}})],1),_c('div',{staticClass:"dashboard-wrapper _border-none"},[_c('v-table',{attrs:{"data":_vm.taskList,"options":{
        title: _vm.$t(_vm.contentPathSegmentsCatalog)['Last tasks'],
        load: _vm.preloader,
        hasData: _vm.taskList.has_data,
        showTooltip: true
      }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }