var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"find"},[_c('s-input',_vm._b({model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}},'s-input',{
        staticWidth: '100%',
        placeholder: _vm.$t(_vm.contentPathSegmentsCatalog)['Search by segment'],
        icon: 'search',
        clearButton: true,
        timeoutReturn: 1000,
        disabled: true
      },false)),(_vm.name)?_c('div',{staticClass:"find-drop"},[(_vm.preloader)?_c('v-preloader',{attrs:{"static":"","clear":""}}):(_vm.segments && _vm.segments.length)?_c('ul',{staticClass:"find-segments"},_vm._l((_vm.segments),function(segment,i){return _c('v-link-segment',_vm._b({key:i},'v-link-segment',{
          projectId: _vm.activeProjectId,
          data: segment
        },false))}),1):_c('div',{staticClass:"find-notfound"},[_vm._v(" "+_vm._s(_vm.$t(_vm.contentPathSegmentsCatalog)['No segments found'])+" ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }