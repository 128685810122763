<template>
  <vue-line type="body">
    <vue-item align="right">{{ item.id }}</vue-item>
    <vue-item>{{ item.name }}</vue-item>
    <vue-item>
      <div class="task-table__cell-project">
        <img
            v-if="project.host && project.host.name"
            :src="'https://favicon.yandex.net/favicon/v2/' + project.host.name"
            :alt="project.label"
        >
        {{ project.label }}
      </div>
    </vue-item>
    <vue-item>{{ item.type }}</vue-item>
    <vue-item align="center">
      <v-status :data="item.status" />
    </vue-item>
    <vue-item>{{ user.surname }} {{ user.name }}</vue-item>
    <vue-item>
      <span>{{ $moment(item.created_at).format(DATE_FORMAT.DAY_MONTH_SHORT_YEAR_DOT) }},
      </span>
      <span class="task-table__cell-date-time">{{ $moment(item.created_at).format('HH:mm') }}</span>
    </vue-item>
    <vue-item align="center">
      <v-button
          class="task-table__cell-download-btn"
          type="text-base"
          icon="save_alt"
          :to="{name: ROUTE_NAME.REDIRECT, query: { q: `${config.api.main}/task/${item.id}/file?project_id=${item.project_id}&skip_regionality=1`}}"
          padding-left="0"
          padding-right="0"
      />
    </vue-item>
  </vue-line>
</template>

<script>
import {mapState} from 'vuex'
import {findOptions} from '@/function'

import ROUTE_NAME from '@/router/routeName'
import {DATE_FORMAT} from '@/const'

const contentPathSegmentsTable = 'services.segments.table'

const status = {
  template: `
    <icon
      v-if="data && data !== null && data !== undefined"
      :class="['task-table__status', template.class]"
      :name="template.icon"
      v-tippy
      :content="template.text"
    />
  `,

  props: {
    data: {
      type: Number
    }
  },

  computed: {
    template () {
      switch (this.data) {
        case 0:
          return {
            text: 'Новое',
            icon: 'add_circle',
            class: '_created'
          }

        case 1:
          return {
            text: this.$t(contentPathSegmentsTable)['Success'],
            icon: 'check_circle',
            class: '_complete'
          }

        case 2:
          return {
            text: 'Остановлено',
            icon: 'error',
            class: '_error'
          }

        case 3:
          return {
            text: 'Выполняется',
            icon: 'access_time',
            class: '_processing'
          }

        case 100:
          return {
            text: 'Выполняется',
            icon: 'access_time',
            class: '_processing'
          }

        case 101:
          return {
            text: 'CH-Stat',
            icon: 'access_time',
            class: '_processing'
          }

        case 254:
          return {
            text: 'Ошибка',
            icon: 'error',
            class: '_error'
          }

        case 255:
          return {
            text: 'Выполняется',
            icon: 'access_time',
            class: '_processing'
          }

        default:
          return {
            text: '',
            icon: '',
            class: ''
          }
      }
    }
  }
}

export default {
  name: 'tableRow',

  components: {
    'v-status': status
  },

  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      ROUTE_NAME,
      DATE_FORMAT
    }
  },

  computed: {
    ...mapState({
      projects: state => state.options.projects,
      config: state => state.config
    }),

    project () {
      return findOptions(this.item.project_id, this.projects.list) || {}
    },

    user () {
      return this.item.user || {}
    }
  }
}
</script>

<style scoped lang="scss">
.task-table {
  &__cell {
    &-name {
      display: flex;
      align-items: center;
    }

    &-project {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }

    &-right {
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    &-date-time {
      color: #9D99AA;
    }

    &-download-btn /deep/ .button__icon {
      width: 20px;
      height: 20px;
    }
  }

  &__status {
    &._complete {
      fill: #4caf50;
    }

    &._error {
      fill: #F44336;
    }

    &._processing {
      fill: #ff9800;
    }

    &._created {
      fill: #ffd100;
    }
  }
}
</style>
