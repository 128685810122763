<template>
  <div class="find">
    <s-input
        v-model="name"
        v-bind="{
          staticWidth: '100%',
          placeholder: $t(contentPathSegmentsCatalog)['Search by segment'],
          icon: 'search',
          clearButton: true,
          timeoutReturn: 1000,
          disabled: true
        }"
    />

    <div
        v-if="name"
        class="find-drop"
    >
      <v-preloader
          v-if="preloader"
          static
          clear
      />
      <ul
          v-else-if="segments && segments.length"
          class="find-segments"
      >
        <v-link-segment
          v-for="(segment, i) in segments"
          :key="i"
          v-bind="{
            projectId: activeProjectId,
            data: segment
          }"
        />
      </ul>

      <div
          v-else
          class="find-notfound"
      >
        {{ $t(contentPathSegmentsCatalog)['No segments found'] }}
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import linkSegment from './link.vue'

const contentPathSegmentsCatalog = 'services.segments.catalog'

export default {
  data () {
    return {
      contentPathSegmentsCatalog,
      name: '',
      preloader: false,
      segments: []
    }
  },

  components: {
    'v-link-segment': linkSegment
  },

  computed: {
    /* ID активного проекта в URL */
    activeProjectId () {
      let id = +this.$route.params.activeProject
      return id ? +id : undefined
    }
  },

  methods: {
    ...mapActions({
      // getSegmentsSearch: 'segments/catalog/getSegmentsSearch'
    })
  },

  watch: {
    name: async function (value) {
      // if (value) {
      //   this.$set(this, 'preloader', true)
      //   this.$set(this, 'segments', [])
      //   let data = await this.getSegmentsSearch({
      //     query: {
      //       project_id: this.activeProjectId,
      //       name: value
      //     }
      //   })
      //   this.$set(this, 'segments', data.segments)
      //   this.$set(this, 'preloader', false)
      // } else {
      //   this.$set(this, 'segments', [])
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
  .find {
    position: relative;
  }

  .find-drop {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 8px 16px;
    z-index: 2;
    max-height: 300px;
    overflow: auto;
    background: #FFFFFF;
    box-shadow: 0 0 8px rgba(69, 81, 101, .15), 0 8px 8px rgba(69, 81, 101, .15);
    border-radius: 6px;
  }

  .find-segments {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
</style>
