<template>
  <div class="dashboard-block _mb-32 subsegment">
    <div
        v-if="category"
        class="catalog__subtitle"
    >
      {{ category.name }}
    </div>

    <div
        v-if="category && category.description"
        class="catalog__description"
    >
      {{ category.description }}
    </div>

    <ul
        v-if="!!links && !!links.length"
        class="subsegment__list"
    >
      <v-link
          v-for="item in links"
          :key="item.id"
          :data="item"
      />
    </ul>

    <ul
        v-if="!!category && !!category.items && !!category.items.length"
        class="subsegment__list"
    >
      <v-link
          v-for="item in category.items"
          :key="item.id"
          :data="item"
      />
    </ul>
  </div>
</template>

<script>
import link from './link'

export default {
  props: {
    category: {
      type: Object
    },

    links: {
      type: Array
    }
  },

  components: {
    'v-link': link
  }
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
