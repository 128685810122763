<template>
  <li class="subsegment__item">
    <router-link
      class="subsegment__link"
      :to="{
        name: ROUTE_NAME.SEGMENTS_CONTENT_LIST,
        params: { activeProject: activeProjectId, catalogId: data.id }
      }"
    >
      <div class="subsegment__title">
        <span>{{ data.name }}</span>
        <v-hint
            icon="info_small"
            tooltip-position="right"
        >
          <div v-html="data.description"></div>
        </v-hint>
      </div>
      <ul class="subsegment__counts">
        <li
            v-for="(item, i) of stats"
            :key="i"
        >
          {{ item.type }}:
          <span :class="{ '_blue': item.count !== '0' }">{{ item.count || 0 | formatCount }}</span>
        </li>
      </ul>
      <icon
          class="subsegment__arrow"
          name="arrow_forward"
      />
    </router-link>
  </li>
</template>

<script>
import ROUTE_NAME from '@/router/routeName'

export default {
  props: {
    data: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },

  data() {
    return {
      contentPathSegmentsCatalog: 'services.segments.catalog',

      ROUTE_NAME
    }
  },

  computed: {
    /* ID активного проекта в URL */
    activeProjectId () {
      let id = +this.$route.params.activeProject
      return id ? +id : undefined
    },

    /* Статистика по выбранному сегменту */
    stats () {
      return this.data.segment
    }
  }
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
